@import './variables';

html,
body,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
}

html {
  font-family: $mainFont;
  font-size: $rootFontSize + px;
  background-color: $substrateColor;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

svg {
  display: block;
}

ul,
ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
