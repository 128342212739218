.SummaryPreview {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RefreshButton {
  position: fixed;
  top: 0;
  right: 0;
}
